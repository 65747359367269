<div class="timetracker-container" *ngIf="!managerView">
  <app-collapsable
    *ngIf="project && project.live_checkin === true && isTodayASelectableDay"
  >
    <h4>Check-In / -Out</h4>
    <div
      class="checkin-disclaimer checkin-teamlead"
      *ngIf="isCurrentUserTeamlead()"
    >
      <p>
        Hier kannst du Mitarbeiter für den Einsatz ein- und auschecken. Wähle ob
        über einen Scan vom QR-Code des Mitarbeiters oder wähle diese manuell
        aus der Liste.
      </p>
      <div class="qr-code-scanner-wrapper">
        <div class="_col">
          <h5>QR-Scan</h5>
          <app-button [lessHeight]="true" (click)="openQrScannerOverlay(true)"
            >Check-In</app-button
          >
          <app-button [lessHeight]="true" (click)="openQrScannerOverlay(false)"
            >Check-Out</app-button
          >
        </div>
        <div class="_col">
          <h5>Manuell</h5>
          <app-button [lessHeight]="true" (click)="openCheckInModal()"
            >Check-IN</app-button
          >
          <app-button [lessHeight]="true" (click)="openCheckOutModal()"
            >Check-Out</app-button
          >
        </div>
      </div>
      <!-- <div class="self-checkin-container">
        <h4>Self Check-In / -Out</h4>
        <div class="two-cols">
          <div class="_col">
            <app-button [lessHeight]="true" (click)="selfCheckIn()">Self Check-IN</app-button>
          </div>
          <div class="_col">
            <app-button [lessHeight]="true" (click)="selfCheckOut()">Self Check-Out</app-button>
          </div>
        </div>
      </div> -->
    </div>
    <div
      class="checkin-disclaimer checkin-regular"
      *ngIf="!isCurrentUserTeamlead()"
    >
      <p>
        Lasse dich von einem Teamleiter vor Ort mit dem unten stehenden QR-Code
        ein- oder auschecken.
      </p>
      <div class="qr-code-wrapper">
        <qrcode
          *ngIf="liveCheckinQrCodeString"
          [qrdata]="liveCheckinQrCodeString"
          [width]="256"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable>
    <h4>Meine Zeiten</h4>
    <div>
      <app-time-block
        *ngIf="
          isCurrentUserTeamlead() ||
          (!isCurrentUserTeamlead() &&
            (!project || project.live_checkin === false))
        "
        blockType="add"
        title="Gesamt"
        [overallDuration]="myTimeOverall"
        (onButtonClick)="addTimeClicked()"
        buttonText="Zeit hinzufügen"
        buttonIcon="plus"
      ></app-time-block>
      <div *ngIf="myTimeSlots && myTimeSlots.length > 0">
        <h5>Einzelansicht</h5>
        <app-time-block
          *ngFor="let timeSlot of myTimeSlots"
          [ngClass]="
            !timeSlot.edited_by_admin &&
            !timeSlot.document_id &&
            !timeSlot.is_already_established_in_datev_export &&
            (isCurrentUserTeamlead() ||
              (!isCurrentUserTeamlead() &&
                (!project || project.live_checkin === false)))
              ? 'selectable'
              : ''
          "
          [selectable]="
            !timeSlot.edited_by_admin &&
            !timeSlot.document_id &&
            !timeSlot.is_already_established_in_datev_export &&
            (isCurrentUserTeamlead() ||
              (!isCurrentUserTeamlead() &&
                (!project || project.live_checkin === false)))
          "
          (click)="editTimeClicked(timeSlot)"
          blockType="single"
          [timeFrom]="timeSlot.time_from"
          [timeTo]="timeSlot.time_to ?? null"
          [timeBreak]="timeSlot.time_break ?? null"
        ></app-time-block>
      </div>
    </div>
  </app-collapsable>
  <div
    class="team-view-container"
    *ngIf="teamTimeSlotDays && teamTimeSlotDays.length > 0"
  >
    <app-collapsable>
      <h4>
        {{ isCurrentUserTeamlead() ? "Team Zeiten" : "" }}
      </h4>
      <div>
        <div class="team-time-slot-day-switch">
          <div
            class="day-switch-button switch-prev"
            (click)="timeSlotDaySwitchClicked(false)"
          >
            <app-svgs type="chevron-left" color="secondary"></app-svgs>
          </div>
          <p>
            {{
              teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex].weekday +
                " " +
                teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex].dateShort
            }}
          </p>
          <div
            class="day-switch-button switch-next"
            (click)="timeSlotDaySwitchClicked(true)"
          >
            <app-svgs type="chevron-right" color="secondary"></app-svgs>
          </div>
        </div>
        <app-time-block
          *ngIf="teamTimeOverall"
          blockType="add"
          title="Gesamt"
          [overallDuration]="teamTimeOverall"
          subText="Lasse die Zeiten täglich vom Kunden prüfen. Nach erfolgter Unterschrift  wird eine Übersicht an den Kunden geschickt."
          (onButtonClick)="openSignModalClicked()"
          [buttonText]="
            hasTimeslotsWithoutDocumentId(
              teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex]
            )
              ? 'Kundenunterschrift'
              : ''
          "
          buttonIcon="page-edit"
        ></app-time-block>
        <div>
          <app-time-block
            [ngClass]="
              !timeSlot.edited_by_admin &&
              !timeSlot.document_id &&
              !timeSlot.is_already_established_in_datev_export
                ? 'selectable'
                : ''
            "
            [selectable]="!timeSlot.edited_by_admin && !timeSlot.document_id"
            *ngFor="let timeSlot of currentVisibleTeamTimeSlots"
            blockType="single"
            [timeFrom]="
              timeSlot.fake_time_from
                ? timeSlot.fake_time_from
                : timeSlot.time_from
            "
            [timeTo]="
              timeSlot.fake_time_to
                ? timeSlot.fake_time_to
                : timeSlot.time_to ?? null
            "
            [timeBreak]="
              timeSlot.fake_time_break || timeSlot.fake_time_break === 0
                ? timeSlot.fake_time_break
                : timeSlot.time_break ?? null
            "
            [hideBreak]="true"
            [title]="timeSlot.title || ''"
            (click)="editTimeBySupervisorClicked(timeSlot)"
          ></app-time-block>
        </div>
      </div>
    </app-collapsable>
  </div>
</div>

<div *ngIf="managerView" class="manager-view">
  <div
    *ngIf="project && project.live_checkin === true && isTodayASelectableDay"
    class="head-row"
  >
    <div class="time-overall-wrapper checkin-buttons-container">
      <h4>Mitarbeiter Check-In/-Out</h4>
      <div class="two-cols">
        <div class="_col">
          <h5>QR-Scan</h5>
          <app-button [lessHeight]="true" (click)="openQrScannerOverlay(true)"
            >Check-In</app-button
          >
          <app-button [lessHeight]="true" (click)="openQrScannerOverlay(false)"
            >Check-Out</app-button
          >
        </div>
        <div class="_col">
          <h5>Manuell</h5>
          <app-button [lessHeight]="true" (click)="openCheckInModal()"
            >Check-IN</app-button
          >
          <app-button [lessHeight]="true" (click)="openCheckOutModal()"
            >Check-Out</app-button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="head-row">
    <div class="time-overall-wrapper">
      <h4>Zeiterfassung eintragen</h4>
      <app-button [lessHeight]="true" (click)="openAddTimeForTopteamerOverlay()"
        >Zeit hinzufügen</app-button
      >
    </div>
  </div>
  <div class="head-row">
    <div class="time-overall-wrapper">
      <p>Zeit gesamt</p>
      <p class="project-overall-time">
        <span>{{ formattedProjectTimeOverall }}</span>
        h
      </p>
    </div>
    <div class="export-button-wrapper" *ngIf="isExportAllVisible">
      <app-button
        (click)="exportCSVClicked()"
        color="white"
        background="transparent"
        iconRight="table-2-columns"
        [lessHeight]="true"
        >Alle Zeiten exportieren</app-button
      >
    </div>
  </div>
  <div class="day-overview-list">
    <div
      class="table-wrapper day-overview-wrapper"
      *ngFor="let teamTimeSlotDay of teamTimeSlotDays; let i = index"
    >
      <div class="table-header">
        <h4>
          <div class="flex-title">
            {{ teamTimeSlotDay.weekday }} {{ teamTimeSlotDay.dateShort }}
            <span>
              Anzahl Einträge:
              {{
                teamTimeSlotDay.timeSlots ? teamTimeSlotDay.timeSlots.length : 0
              }}
            </span>
            <span>
              Vollständige:
              {{ teamTimeSlotDay.completeTimeslots }}
            </span>
            <span>Fehlende Checkouts: {{ teamTimeSlotDay.openCheckouts }}</span>
          </div>
        </h4>
        <div class="day-overall-time text-with-icon icon-left">
          <app-svgs type="timer" color="primary40"></app-svgs>
          <p>{{ teamTimeSlotDay.overallTimeFormatted }}</p>
        </div>
        <app-button
          *ngIf="hasTimeslotsWithoutDocumentId(teamTimeSlotDay)"
          (click)="openSignModalClicked(teamTimeSlotDay)"
          color="secondary-gradient"
          background="secondary"
          iconRight="page-edit"
          [lessHeight]="true"
          >Kundenunterschrift</app-button
        >
        <app-button
          *ngIf="!hasTimeslotsWithoutDocumentId(teamTimeSlotDay)"
          style="white-space: nowrap"
          (click)="resendTimeDocumentClicked(teamTimeSlotDay)"
          color="white"
          background="transparent"
          [lessHeight]="true"
          >Übersicht erneut senden</app-button
        >
        <app-button
          *ngIf="!hasTimeslotsWithoutDocumentId(teamTimeSlotDay)"
          (click)="exportCSVClicked(teamTimeSlotDay)"
          color="white"
          background="transparent"
          iconRight="table-2-columns"
          [lessHeight]="true"
          >Export</app-button
        >
        <app-svgs
          (click)="
            teamTimeSlotDays[i].collapsed = !teamTimeSlotDays[i].collapsed
          "
          [type]="teamTimeSlotDay.collapsed ? 'eye' : 'eye-crossed'"
          color="secondary"
        ></app-svgs>
      </div>
      <div class="table-wrapper">
        <table @fade *ngIf="!teamTimeSlotDay.collapsed">
          <thead>
            <tr>
              <th>Nr</th>
              <th style="text-align: left">Name</th>
              <th>von</th>
              <th>bis</th>
              <!-- <th>Pause</th> -->
              <th>Arbeitsdauer</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="timeslot-row"
              *ngFor="let timeSlot of teamTimeSlotDay.timeSlots; let i = index"
              [ngClass]="isTimeslotEditable(timeSlot) ? 'selectable' : ''"
              (click)="editTimeBySupervisorClicked(timeSlot)"
            >
              <td>{{ i + 1 }}</td>
              <td style="text-align: left">
                <strong>{{ timeSlot.title }}</strong>
              </td>
              <td>
                {{
                  (timeSlot.fake_time_from || timeSlot.fake_time_from === 0
                    ? timeSlot.fake_time_from
                    : timeSlot.time_from
                  ) | date : "H:mm" : undefined : "de-DE"
                }}
              </td>
              <td>
                {{
                  (timeSlot.fake_time_to || timeSlot.fake_time_to === 0
                    ? timeSlot.fake_time_to
                    : timeSlot.time_to
                  ) | date : "H:mm" : undefined : "de-DE"
                }}
                <strong *ngIf="!timeSlot.time_to" class="alert"
                  >Checkout ausstehend!</strong
                >
              </td>
              <!-- <td>
              {{ timeSlot.formattedBreak }}
              h
            </td> -->
              <td>
                <strong *ngIf="timeSlot.formattedDuration"
                  >{{ timeSlot.formattedDuration }} h</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- ADD/EDIT TIME MODAL -->
<app-modal
  class="add-time-modal"
  *ngIf="(!managerView && addTimeModalVisible) || (data.debug && false)"
  (close)="closeModalClicked()"
>
  <div class="header">
    <h5>Zeit {{ addTimeModalEditTime ? "bearbeiten" : "hinzufügen" }}</h5>
    <h6
      class="alert margin-top-10"
      *ngIf="!!addTimeValues?.is_already_established_in_datev_export"
    >
      Zeit kann nicht mehr geändert werden, da sie schon festgesetzt wurde. Nur
      die Kundenzeit kann noch geändert werden.
    </h6>
  </div>
  <div
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="calendar" color="primary40"></app-svgs>
      <p>Tag</p>
    </div>
    <div class="date-pick-row">
      <div
        *ngFor="let selectableDay of selectableDays; let i = index"
        (click)="selectableDayClicked(i)"
        [class]="'day-pick-wrapper'"
        [id]="'day-pick-' + i"
        [ngClass]="{
          unselectable: !selectableDay.selectable
        }"
      >
        <p class="day-string">{{ selectableDay.weekdayShort }}</p>
        <p class="date-string">{{ selectableDay.dateShort }}</p>
        <div
          class="input-wrapper-radio"
          [ngClass]="selectableDay.selected ? 'selected' : ''"
        >
          <app-svgs type="checkmark" color="white"></app-svgs>
        </div>
      </div>
    </div>
  </div>
  <div
    class="time-wrapper"
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="clock" color="primary40"></app-svgs>
      <p>Einsatzzeit</p>
    </div>
    <div class="time-input-row">
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeFromInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
      <p>
        bis<span
          *ngIf="overMidnightString"
          class="over-midnight-string"
          (click)="overMidnightStringClicked()"
          >{{ overMidnightString }}<span>?</span>
        </span>
      </p>
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeToInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
    </div>
  </div>
  <div
    class="break-wrapper"
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="yoga" color="primary40"></app-svgs>
      <p>Pause</p>
    </div>
    <div class="break-time-row">
      <app-plus-minus-input
        [step]="1"
        [min]="breakHoursInputMin"
        unit="h"
        [value]="breakHoursInputValue"
        (valueChange)="breakHoursValueChanged($event)"
      ></app-plus-minus-input>
      <app-plus-minus-input
        [step]="15"
        [min]="breakMinutesInputMin"
        [max]="60"
        unit="min"
        [value]="breakMinutesInputValue"
        (valueChange)="breakMinutesValueChanged($event)"
      ></app-plus-minus-input>
    </div>
    <div *ngIf="breakInputIsTooLow">
      <p>
        Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
        {{ minBreakMinutesValue }}min
      </p>
    </div>
  </div>
  <div *ngIf="formattedTimeDuration" class="working-time-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="timer" color="primary40"></app-svgs>
      <p>{{ formattedTimeDuration }}</p>
    </div>
  </div>
  <div class="fake-data-container" *ngIf="addTimeModalEditBySupervisor">
    <div class="input-wrapper checkbox">
      <input
        type="checkbox"
        name="modify-time"
        [value]="addTimeValues.fake_time_from ? true : false"
        [checked]="addTimeValues.fake_time_from ? true : false"
        (change)="modalTimeFakeCheckboxChange($event)"
      />
      <label for="modify-time">Zeit für Kunde ändern</label>
    </div>
    <div
      class="fake-data-formular"
      *ngIf="addTimeValues.fake_time_from && addTimeValues.fake_time_from > 0"
    >
      <div class="time-wrapper">
        <div class="text-with-icon icon-left">
          <app-svgs type="clock" color="primary40"></app-svgs>
          <p>Einsatzzeit</p>
        </div>
        <div class="time-input-row">
          <div class="input-wrapper">
            <app-time-picker
              [(ngModel)]="fakeTimeFromInputValue"
              (ngModelChange)="refreshAddTimeValues()"
            >
            </app-time-picker>
          </div>
          <p>
            bis<span
              *ngIf="fakeOverMidnightString"
              class="over-midnight-string"
              (click)="overMidnightStringClicked()"
              >{{ fakeOverMidnightString }}<span>?</span>
            </span>
          </p>
          <div class="input-wrapper">
            <app-time-picker
              [(ngModel)]="fakeTimeToInputValue"
              (ngModelChange)="refreshAddTimeValues()"
            >
            </app-time-picker>
          </div>
        </div>
      </div>

      <div class="break-wrapper">
        <div class="text-with-icon icon-left">
          <app-svgs type="yoga" color="primary40"></app-svgs>
          <p>Pause</p>
        </div>
        <div class="break-time-row">
          <app-plus-minus-input
            [step]="1"
            [min]="0"
            unit="h"
            [value]="fakeBreakHoursInputValue"
            (valueChange)="fakeBreakHoursValueChanged($event)"
          ></app-plus-minus-input>
          <app-plus-minus-input
            [step]="15"
            [min]="0"
            [max]="60"
            unit="min"
            [value]="fakeBreakMinutesInputValue"
            (valueChange)="fakeBreakMinutesValueChanged($event)"
          ></app-plus-minus-input>
        </div>
      </div>
      <div>
        <p>
          Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
          {{ minBreakMinutesValue }}min
        </p>
      </div>
    </div>
    <div
      *ngIf="
        addTimeValues.fake_time_from &&
        addTimeValues.fake_time_from > 0 &&
        fakeFormattedTimeDuration
      "
      class="working-time-row"
    >
      <div class="text-with-icon icon-left">
        <app-svgs type="timer" color="primary40"></app-svgs>
        <p>{{ fakeFormattedTimeDuration }}</p>
      </div>
    </div>
  </div>
  <app-button
    *ngIf="!addTimeModalEditTime"
    (click)="modalAddTimeClicked()"
    color="secondary"
    iconRight="plus"
    background="secondary"
    >Zeit hinzufügen</app-button
  >
  <app-button
    *ngIf="addTimeModalEditTime"
    (click)="modalUpdateTimeClicked()"
    color="secondary"
    background="secondary"
    >Zeit aktualisieren</app-button
  >
  <p
    class="delete-time-button"
    *ngIf="
      addTimeModalEditTime &&
      !addTimeValues.is_already_established_in_datev_export
    "
    (click)="modalDeleteTimeClicked()"
  >
    Zeit löschen
  </p>
</app-modal>

<!-- MANAGER MODAL -->
<app-modal
  class="manager-modal"
  *ngIf="managerView && addTimeModalVisible"
  (close)="closeModalClicked()"
>
  <div class="header">
    <h4>
      {{
        (addTimeValues.fake_time_from
          ? addTimeValues.fake_time_from
          : addTimeValues.time_from
        ) | date : "EEEE dd.MM.YYYY" : undefined : "de-DE"
      }}
    </h4>
    <h2 class="margin-top-10">{{ addTimeValues.title }}</h2>
    <h6
      class="alert margin-top-10"
      *ngIf="!!addTimeValues.is_already_established_in_datev_export"
    >
      Zeit kann nicht mehr geändert werden, da sie schon festgesetzt wurde. Nur
      die Kundenzeit kann noch geändert werden.
    </h6>
  </div>
  <div class="time-wrapper">
    <div class="text-with-icon icon-left">
      <app-svgs type="clock" color="primary40"></app-svgs>
      <p>Einsatzzeit</p>
    </div>
    <div
      class="time-input-row"
      [ngClass]="{
        disabled: !!addTimeValues.is_already_established_in_datev_export
      }"
    >
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeFromInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
      <p>
        bis<span
          *ngIf="overMidnightString"
          class="over-midnight-string"
          (click)="overMidnightStringClicked()"
          >{{ overMidnightString }}<span>?</span>
        </span>
      </p>
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeToInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
    </div>
  </div>
  <div
    class="break-wrapper"
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="yoga" color="primary40"></app-svgs>
      <p>Pause</p>
    </div>
    <div class="break-time-row">
      <app-plus-minus-input
        [step]="1"
        [min]="breakHoursInputMin"
        unit="h"
        [value]="breakHoursInputValue"
        (valueChange)="breakHoursValueChanged($event)"
        (disabled)="(!!addTimeValues.is_already_established_in_datev_export)"
      ></app-plus-minus-input>
      <app-plus-minus-input
        [step]="15"
        [min]="breakMinutesInputMin"
        [max]="60"
        unit="min"
        [value]="breakMinutesInputValue"
        (valueChange)="breakMinutesValueChanged($event)"
        (disabled)="(!!addTimeValues.is_already_established_in_datev_export)"
      ></app-plus-minus-input>
    </div>
    <div *ngIf="breakInputIsTooLow">
      <p>
        Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
        {{ minBreakMinutesValue }}min
      </p>
    </div>
  </div>
  <div *ngIf="formattedTimeDuration" class="working-time-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="timer" color="primary40"></app-svgs>
      <p>{{ formattedTimeDuration }}</p>
    </div>
  </div>
  <div
    *ngIf="addTimeValues.fake_time_from && addTimeValues.fake_time_from > 0"
    class="time-row fake-time-wrapper"
  >
    <div class="time-wrapper">
      <div class="text-with-icon icon-left">
        <app-svgs type="clock" color="primary40"></app-svgs>
        <p>Einsatzzeit</p>
      </div>
      <div class="time-input-row">
        <div class="input-wrapper">
          <app-time-picker
            [(ngModel)]="fakeTimeFromInputValue"
            (ngModelChange)="refreshAddTimeValues()"
          >
          </app-time-picker>
        </div>
        <p>
          bis<span
            *ngIf="fakeOverMidnightString"
            class="over-midnight-string"
            (click)="overMidnightStringClicked()"
            >{{ fakeOverMidnightString }}<span>?</span>
          </span>
        </p>
        <div class="input-wrapper">
          <app-time-picker
            [(ngModel)]="fakeTimeToInputValue"
            (ngModelChange)="refreshAddTimeValues()"
          >
          </app-time-picker>
        </div>
      </div>
    </div>
    <div class="break-wrapper">
      <div class="text-with-icon icon-left">
        <app-svgs type="yoga" color="primary40"></app-svgs>
        <p>Pause</p>
      </div>
      <div class="break-time-row">
        <app-plus-minus-input
          [step]="1"
          [min]="0"
          unit="h"
          [value]="fakeBreakHoursInputValue"
          (valueChange)="fakeBreakHoursValueChanged($event)"
        ></app-plus-minus-input>
        <app-plus-minus-input
          [step]="15"
          [min]="0"
          [max]="60"
          unit="min"
          [value]="fakeBreakMinutesInputValue"
          (valueChange)="fakeBreakMinutesValueChanged($event)"
        ></app-plus-minus-input>
      </div>
    </div>
    <div>
      <p>
        Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
        {{ minBreakMinutesValue }}min
      </p>
    </div>
  </div>
  <div
    *ngIf="
      addTimeValues.fake_time_from &&
      addTimeValues.fake_time_from > 0 &&
      fakeFormattedTimeDuration
    "
    class="working-time-row"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="timer" color="primary40"></app-svgs>
      <p>{{ fakeFormattedTimeDuration }}</p>
    </div>
  </div>
  <div class="checkbox-button-row">
    <div class="input-wrapper checkbox">
      <input
        type="checkbox"
        name="modify-time"
        [value]="addTimeValues.fake_time_from ? true : false"
        [checked]="addTimeValues.fake_time_from ? true : false"
        (change)="modalTimeFakeCheckboxChange($event)"
      />
      <label for="modify-time">Zeit für Kunde ändern</label>
    </div>
    <app-button
      *ngIf="addTimeModalEditTime"
      (click)="modalUpdateTimeClicked()"
      color="secondary"
      background="secondary"
      [lessHeight]="true"
      >Speichern</app-button
    >
  </div>
  <div
    *ngIf="extraTimesList && extraTimesList.length > 0"
    class="manager-modal-footer-wrapper"
  >
    <p>weitere Arbeitszeiten</p>
    <div class="manager-modal-extra-times-list">
      <table class="">
        <tbody>
          <tr *ngFor="let extraTimeSlot of extraTimesList">
            <td>
              <strong>{{
                (extraTimeSlot.fake_time_from
                  ? extraTimeSlot.fake_time_from
                  : extraTimeSlot.time_from
                ) | date : "EE dd.MM.yyyy" : undefined : "de-DE"
              }}</strong>
            </td>
            <td>
              {{
                (extraTimeSlot.fake_time_from
                  ? extraTimeSlot.fake_time_from
                  : extraTimeSlot.time_from
                ) | date : "H:mm"
              }}
              -
              {{
                (extraTimeSlot.fake_time_to
                  ? extraTimeSlot.fake_time_to
                  : extraTimeSlot.time_to
                ) | date : "H:mm"
              }}
            </td>
            <td>{{ extraTimeSlot.formattedBreak }} h Pause</td>
            <td>{{ extraTimeSlot.formattedDuration }} h Arbeitsdauer</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p
    class="delete-time-button"
    *ngIf="
      addTimeModalEditTime &&
      !addTimeValues.is_already_established_in_datev_export
    "
    (click)="modalDeleteTimeClicked()"
  >
    Zeit löschen
  </p>
</app-modal>

<!-- ADD TIME FOR TOPTEAMER MODAL -->
<app-modal
  class="add-time-modal"
  *ngIf="showAddTimeForTopteamerOverlay"
  (close)="closeAddTimeForTopteamerOverlay()"
>
  <div class="header">
    <h5>Zeit hinzufügen</h5>
  </div>
  <div>
    <h4>Mitarbeiter</h4>
    <ng-select
      *ngIf="finalizedApplicationUsers"
      class="hide-delete-button"
      bindLabel="user.profile.id"
      [(ngModel)]="selectedApplicantForCheckin"
      (change)="selectedApplicantForAddTimeChanged($event)"
      [items]="finalizedApplicationUsers"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
    </ng-select>
  </div>
  <div
    *ngIf="selectedApplicantForCheckin && !saveTimeSlotInProgress"
    [ngClass]="{
      disabled: !selectedApplicantForCheckin || saveTimeSlotInProgress
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="calendar" color="primary40"></app-svgs>
      <p>Tag</p>
    </div>
    <div class="date-pick-row">
      <div
        *ngFor="let selectableDay of selectableDays; let i = index"
        (click)="selectableDayClicked(i)"
        [class]="'day-pick-wrapper'"
        [id]="'day-pick-' + i"
        [ngClass]="{
          unselectable: !selectableDay.selectable
        }"
      >
        <p class="day-string">{{ selectableDay.weekdayShort }}</p>
        <p class="date-string">{{ selectableDay.dateShort }}</p>
        <div
          class="input-wrapper-radio"
          [ngClass]="selectableDay.selected ? 'selected' : ''"
        >
          <app-svgs type="checkmark" color="white"></app-svgs>
        </div>
      </div>
    </div>
  </div>
  <div
    class="time-wrapper"
    *ngIf="selectedApplicantForCheckin && !saveTimeSlotInProgress"
    [ngClass]="{
      disabled:
        !selectedApplicantForCheckin ||
        !!addTimeValues.is_already_established_in_datev_export ||
        saveTimeSlotInProgress
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="clock" color="primary40"></app-svgs>
      <p>Einsatzzeit</p>
    </div>
    <div class="time-input-row">
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeFromInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
      <p>
        bis<span
          *ngIf="overMidnightString"
          class="over-midnight-string"
          (click)="overMidnightStringClicked()"
          >{{ overMidnightString }}<span>?</span>
        </span>
      </p>
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeToInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
    </div>
  </div>
  <div
    class="break-wrapper"
    *ngIf="selectedApplicantForCheckin && !saveTimeSlotInProgress"
    [ngClass]="{
      disabled:
        !selectedApplicantForCheckin ||
        !!addTimeValues.is_already_established_in_datev_export ||
        saveTimeSlotInProgress
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="yoga" color="primary40"></app-svgs>
      <p>Pause</p>
    </div>
    <div class="break-time-row">
      <app-plus-minus-input
        [step]="1"
        [min]="breakHoursInputMin"
        unit="h"
        [value]="breakHoursInputValue"
        (valueChange)="breakHoursValueChanged($event)"
      ></app-plus-minus-input>
      <app-plus-minus-input
        [step]="15"
        [min]="breakMinutesInputMin"
        [max]="60"
        unit="min"
        [value]="breakMinutesInputValue"
        (valueChange)="breakMinutesValueChanged($event)"
      ></app-plus-minus-input>
    </div>
    <div *ngIf="breakInputIsTooLow">
      <p>
        Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
        {{ minBreakMinutesValue }}min
      </p>
    </div>
  </div>
  <div *ngIf="formattedTimeDuration" class="working-time-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="timer" color="primary40"></app-svgs>
      <p>{{ formattedTimeDuration }}</p>
    </div>
  </div>
  <app-button
    *ngIf="!addTimeModalEditTime"
    (click)="saveNewTimeSlotForTopteamer()"
    color="secondary"
    iconRight="plus"
    background="secondary"
    [disabled]="
      saveTimeSlotInProgress ||
      !addTimeValues.time_from ||
      !addTimeValues.time_to ||
      !selectedApplicantForCheckin ||
      !!addTimeValues.is_already_established_in_datev_export
    "
    [isLoading]="saveTimeSlotInProgress"
    >Zeit hinzufügen</app-button
  >
</app-modal>

<!-- SIGN TIMES MODAL -->
<app-modal
  class="sign-times-modal"
  *ngIf="customerSignModalVisible"
  (close)="closeModalClicked()"
>
  <h5>Zeiten bestätigen</h5>
  <p class="subtitle-date">
    {{
      teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex].weekday +
        " " +
        teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex].dateLong
    }}
  </p>
  <div class="overall-time-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="clock" color="primary40"></app-svgs>
      <p class="overall-time">
        {{
          teamTimeSlotDays[currentActiveTeamTimeSlotDayIndex]
            .overallTimeFormatted
        }}
      </p>
      <p>Gesamtstunden</p>
    </div>
  </div>
  <div class="email-input-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="email" color="primary40"></app-svgs>
      <p>Kopie an folgende E-Mail</p>
    </div>
    <div class="input-wrapper">
      <input
        type="email"
        placeholder="E-Mail"
        [(ngModel)]="customerSignModalEmail"
      />
    </div>
  </div>
  <div class="signature-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="page-edit" color="primary40"></app-svgs>
      <p>Unterschrift</p>
    </div>
    <div class="signature-wrapper">
      <app-svgs
        type="refresh"
        color="secondary"
        (click)="signatureClear()"
      ></app-svgs>
      <app-signature-pad
        [options]="signaturePadOpts"
        id="signatureCanvas"
        (onEndEvent)="signatureDrawFinish()"
      ></app-signature-pad>
      <div class="line"></div>
    </div>
  </div>
  <app-button
    (click)="modalSignatureSendButtonClicked()"
    color="secondary"
    background="secondary"
    [disabled]="isUploading"
    [isLoading]="isUploading"
    >Arbeitszeiten bestätigen</app-button
  >
</app-modal>

<!-- WARNING MODALS -->
<app-modal
  class="over-midnight-hint-modal"
  *ngIf="overMidnightHintModalVisible"
  (close)="closeModalClicked('midnight')"
>
  <h5>Über zwei Tage</h5>
  <p>
    Da Ihre angegebene "Zeit bis" früher ist als die "Zeit von" gehen wir davon
    aus, dass Ihre Arbeitszeit in den nächsten Tag hinein geht.
  </p>
</app-modal>

<!-- QR Scanner Modal -->
<app-modal
  *ngIf="showQrScannerModal"
  [customWidth]="'500px'"
  [customMaxWidth]="'95%'"
  class="checkin-qrcode-scanner-modal"
  (close)="showQrScannerModal = false"
>
  <h5>{{ isForCheckIn ? "Check-In" : "Check-Out" }} Scan</h5>
  <app-qr-scanner-element
    (qrCodeResultEvent)="newQrCodeScanned($event)"
  ></app-qr-scanner-element>
</app-modal>

<!-- Check-In Modal -->
<app-modal
  *ngIf="showCheckInModal"
  [customWidth]="'500px'"
  [customMaxWidth]="'95%'"
  class="check-in-modal"
  (close)="closeCheckInModal()"
>
  <h4>Check-In</h4>
  <div>
    <h4>Mitarbeiter auswählen</h4>
    <ng-select
      *ngIf="finalizedApplicationUsers"
      class="hide-delete-button"
      bindLabel="user.profile.id"
      [(ngModel)]="selectedApplicantForCheckin"
      (change)="selectedApplicantForCheckInChanged()"
      [items]="finalizedApplicationUsers"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
    </ng-select>
  </div>
  <div class="text-with-icon icon-left">
    <app-svgs type="calendar" color="primary40"></app-svgs>
    <p>{{ addTimeValues.time_from | date : "EEEE dd.MM.YYYY" }}</p>
  </div>
  <div class="text-with-icon icon-left">
    <app-svgs type="clock" color="primary40"></app-svgs>
    <p>Einsatzbeginn</p>
  </div>
  <div class="time-input-row">
    <div class="input-wrapper">
      <app-time-picker
        [(ngModel)]="timeFromInputValue"
        (ngModelChange)="checkInStartTimeChanged()"
      >
      </app-time-picker>
    </div>
  </div>
  <p *ngIf="hasPendingCheckIn" class="color-fail">
    Es gibt bereits einen offenen Check-In, bitte checken sie die Person erst
    aus bevor Sie einen neuen Check-In durchführen können
  </p>
  <app-button
    *ngIf="!hasPendingCheckIn"
    [disabled]="saveTimeSlotInProgress"
    [isLoading]="saveTimeSlotInProgress"
    (click)="saveCheckIn()"
    >Einchecken</app-button
  >
</app-modal>

<!-- Check-Out Modal -->
<app-modal
  *ngIf="showCheckOutModal"
  [customWidth]="'500px'"
  [customMaxWidth]="'95%'"
  class="add-time-modal check-out-modal"
  (close)="closeCheckOutModal()"
>
  <h4>Check-Out</h4>
  <div>
    <h4>Mitarbeiter</h4>
    <ng-select
      *ngIf="finalizedApplicationUsers"
      class="hide-delete-button"
      bindLabel="user.profile.id"
      [(ngModel)]="selectedApplicantForCheckin"
      (change)="selectedApplicantForCheckOutChanged()"
      [items]="finalizedApplicationUsers"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.user?.profile?.vorname + " " + item.user?.profile?.nachname }}
      </ng-template>
    </ng-select>
  </div>
  <div *ngIf="hasPendingCheckIn" class="text-with-icon icon-left">
    <app-svgs type="calendar" color="primary40"></app-svgs>
    <p>{{ addTimeValues.time_from | date : "EEEE dd.MM.YYYY" }}</p>
  </div>
  <div
    *ngIf="hasPendingCheckIn"
    class="time-wrapper"
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="clock" color="primary40"></app-svgs>
      <p>Einsatzzeit</p>
    </div>
    <div class="time-input-row">
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeFromInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
      <p>
        bis<span
          *ngIf="overMidnightString"
          class="over-midnight-string"
          (click)="overMidnightStringClicked()"
          >{{ overMidnightString }}<span>?</span>
        </span>
      </p>
      <div class="input-wrapper">
        <app-time-picker
          [(ngModel)]="timeToInputValue"
          (ngModelChange)="refreshAddTimeValues(true, true)"
        >
        </app-time-picker>
      </div>
    </div>
  </div>
  <div
    *ngIf="hasPendingCheckIn"
    class="break-wrapper"
    [ngClass]="{
      disabled: !!addTimeValues.is_already_established_in_datev_export
    }"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="yoga" color="primary40"></app-svgs>
      <p>Pause</p>
    </div>
    <div class="break-time-row">
      <app-plus-minus-input
        [step]="1"
        [min]="breakHoursInputMin"
        unit="h"
        [value]="breakHoursInputValue"
        (valueChange)="breakHoursValueChanged($event)"
      ></app-plus-minus-input>
      <app-plus-minus-input
        [step]="15"
        [min]="breakMinutesInputMin"
        [max]="60"
        unit="min"
        [value]="breakMinutesInputValue"
        (valueChange)="breakMinutesValueChanged($event)"
      ></app-plus-minus-input>
    </div>
    <div *ngIf="breakInputIsTooLow">
      <p>
        Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
        {{ minBreakMinutesValue }}min
      </p>
    </div>
  </div>
  <div
    *ngIf="hasPendingCheckIn && formattedTimeDuration"
    class="working-time-row"
  >
    <div class="text-with-icon icon-left">
      <app-svgs type="timer" color="primary40"></app-svgs>
      <p>{{ formattedTimeDuration }}</p>
    </div>
  </div>
  <div
    class="fake-data-container"
    *ngIf="hasPendingCheckIn && addTimeModalEditBySupervisor"
  >
    <div class="input-wrapper checkbox">
      <input
        type="checkbox"
        name="modify-time"
        [value]="addTimeValues.fake_time_from ? true : false"
        [checked]="addTimeValues.fake_time_from ? true : false"
        (change)="modalTimeFakeCheckboxChange($event)"
      />
      <label for="modify-time">Zeit für Kunde ändern</label>
    </div>
    <div
      class="fake-data-formular"
      *ngIf="addTimeValues.fake_time_from && addTimeValues.fake_time_from > 0"
    >
      <div class="time-wrapper">
        <div class="text-with-icon icon-left">
          <app-svgs type="clock" color="primary40"></app-svgs>
          <p>Einsatzzeit</p>
        </div>
        <div class="time-input-row">
          <div class="input-wrapper">
            <app-time-picker
              [(ngModel)]="fakeTimeFromInputValue"
              (ngModelChange)="refreshAddTimeValues()"
            >
            </app-time-picker>
          </div>
          <p>
            bis<span
              *ngIf="fakeOverMidnightString"
              class="over-midnight-string"
              (click)="overMidnightStringClicked()"
              >{{ fakeOverMidnightString }}<span>?</span>
            </span>
          </p>
          <div class="input-wrapper">
            <app-time-picker
              [(ngModel)]="fakeTimeToInputValue"
              (ngModelChange)="refreshAddTimeValues()"
            >
            </app-time-picker>
          </div>
        </div>
      </div>

      <div class="break-wrapper">
        <div class="text-with-icon icon-left">
          <app-svgs type="yoga" color="primary40"></app-svgs>
          <p>Pause</p>
        </div>
        <div class="break-time-row">
          <app-plus-minus-input
            [step]="1"
            [min]="0"
            unit="h"
            [value]="fakeBreakHoursInputValue"
            (valueChange)="fakeBreakHoursValueChanged($event)"
          ></app-plus-minus-input>
          <app-plus-minus-input
            [step]="15"
            [min]="0"
            [max]="60"
            unit="min"
            [value]="fakeBreakMinutesInputValue"
            (valueChange)="fakeBreakMinutesValueChanged($event)"
          ></app-plus-minus-input>
        </div>
      </div>
      <div>
        <p>
          Gesetzliche Pausenzeit: {{ minBreakHoursValue }}h
          {{ minBreakMinutesValue }}min
        </p>
      </div>
    </div>
    <div
      *ngIf="
        addTimeValues.fake_time_from &&
        addTimeValues.fake_time_from > 0 &&
        fakeFormattedTimeDuration
      "
      class="working-time-row"
    >
      <div class="text-with-icon icon-left">
        <app-svgs type="timer" color="primary40"></app-svgs>
        <p>{{ fakeFormattedTimeDuration }}</p>
      </div>
    </div>
  </div>
  <app-button
    *ngIf="hasPendingCheckIn"
    [disabled]="saveTimeSlotInProgress"
    [isLoading]="saveTimeSlotInProgress"
    (click)="saveCheckOut()"
    >Auschecken</app-button
  >
  <p *ngIf="!hasPendingCheckIn && !selectedApplicantForCheckin">
    Bitte wählen Sie einen Mitarbeiter aus.
  </p>
  <p
    *ngIf="!hasPendingCheckIn && selectedApplicantForCheckin"
    class="color-fail"
  >
    Es gibt zu dem ausgewähltem Mitarbeiter keinen offenen Check-In Eintrag,
    bitte checken sie die Person erst ein bevor Sie einen Check-Out durchführen
    können
  </p>
</app-modal>
